<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="12" class="pb-1 pt-0 h-100">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :href="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="10" class="pt-1 h-100">
                    <span class="headline font-weight-medium">Impresoras</span>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col cols="6">
                    <v-card>
                        <v-card-title>
                            <span>Impresora</span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-combobox
                                            v-model="impresora_tickets"
                                            label="Impresora para tickets"
                                            :items="impresoras"
                                            outlined
                                            hide-details>
                                        </v-combobox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-btn
                                            dark
                                            color="success"
                                            @click="setImpresoraTickets"
                                        >
                                            Guardar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

        </template>
    </v-container>
</template>

<script>
import printUtil from '@/plugins/print';
import cloneDeep from 'lodash/cloneDeep';

export default {
    name: 'Printers',
    data() {
        return {
            itemsBreadcrumbs: [
                {
                    text: 'Configuración',
                    disabled: true,
                    href: '',
                }
            ],
            impresoras: [],
            impresora_tickets: '',
            impresora_cotizaciones: '',
        }
    },
    created() {
        this.impresora_tickets = localStorage.getItem('impresora_tickets')
        this.impresora_cotizaciones = localStorage.getItem('impresora_cotizaciones')
        let print = new printUtil()
        print.launch().then(() => {
            console.log('CONECTADO')
            print.findPrinters().then((resp) => {
                this.impresoras = cloneDeep(resp);
            }).catch((err) => {
                console.error(err)
            })
        })
    },
    methods:
    {
        setImpresoraTickets() {
            localStorage.setItem('impresora_tickets', this.impresora_tickets)                
            console.log(this.impresora_tickets)
        },
        setImpresoraCotizaciones() {
            localStorage.setItem('impresora_cotizaciones', this.impresora_cotizaciones)
            console.log(this.impresora_cotizaciones)
        }
    }
}
</script>